import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section, BreadCrumb, ComingSoon } from '../components/Section'
import { Link } from "gatsby"
import styled from 'styled-components'
import BgImage from '../images/faq.jpg'
// import DotteLineArrowIcon from '../components/Icons/DotteLineArrowIcon'
// import PlusIcon from '../components/Icons/PlusIcon'
import BreakpointDown from '../components/Media/BreakpointDown'
// import BreakpointUp from '../components/Media/BreakpointUp'
// const FadeIn = .`
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// `
const SectionBgAfter = styled(Section)`
 &:after{
   right:0;
   left:0;
   background-size:cover;
 }
 .container{
   z-index:1;
 }
`
const BreadCrumbWhite = styled(BreadCrumb)`
 a{
   color:#fff;
   &:hover{
     color:#fff;
   }
 }
`
const PageTitle = styled.h1`
 margin-bottom:0;
 color:#fff;
 text-transform: capitalize;
`
const PageTitleWrapper = styled.div`
text-align:center;
color:#fff;
margin-top:20px;
`

// Faq tabs and accordions 

const SectionBg = styled(Section)`
position:relative;
z-index: 2;
&:before,
&:after{
  width:50%;
}
${BreakpointDown.xl`
&:after{
  display:none;
}
`}
.container{z-index:1;}
`


// const FaqRow = styled.div`
//  display:flex;
//  flex-wrap:wrap;
//  justify-content:space-around;
// `
// const FaqColumn = styled.div`
//   flex: 0 0 50%;
//   width:50%;
//   position:relative;
//   padding-top:150px;
//   padding-bottom:150px;
//   ${BreakpointDown.xl`
//       flex: 0 0 100%;
//       width:100%;
//   `}
//   &:first-child{
//     border-right: 1px solid #00000033;
//     box-shadow: 1px 0px 2px 0px #00000033;
//     background:#fff;
//     ${BreakpointDown.xl`
//       padding-top:30px;
//       padding-bottom:0px;
//       border-right:0;
//       box-shadow:none;
//     `
//   }
//   }
//   &:last-child{
//     padding: 105px;
//     ${BreakpointDown.xl`
//         padding: 30px 0px;
//       `
//   }
//    &:before{
//      content:"?";
//      font-size:800px;
//      font-weight:900;
//       position:absolute;
//       left: 50%;
//       top: 40%;
//       transform: translate(-50%, -50%);
//      font-family: "Nunito Sans", sans-serif;
//      text-transform:uppercase;
//      letter-spacing: -8px;
//      color: #000000;
//      opacity: 0.05;
//      ${BreakpointDown.xl`
//        display:none;
//     `
//   }
//    }
//   }
// `
// const TabList = styled.div` 
// `
// const TabListDesktop = styled(TabList)`
// display:none;
// ${BreakpointUp.xl`
//    display:inherit
// `
//   }
// `
// const TabListMobile = styled(TabList)`
// display:none;
// ${BreakpointDown.xl`
//    display:inherit
//    `
//   }
// `
// const TabListItem = styled.div`
// padding-top:30px;
// padding-bottom:30px;
// display:flex;
// justify-content:space-between;
// align-items:center;
// padding-left:20px;
// padding-right:20px;
// margin-bottom:0;
// position: relative;
// font-family: "Nunito Sans", sans-serif;
// line-height: 24px;
//   font-weight: bold;
//   cursor: pointer;
// ${BreakpointDown.xl`
//   flex-direction:column;
//   background:#fff;
//   border-bottom: 1px solid #00000033;
//   padding-left:15px;
//   padding-right:15px;
//   padding-top:20px;
// padding-bottom:20px;
//  &:nth-of-type(){
//    border-top: 1px solid #00000033;
//  }
//  `}
//  ${BreakpointDown.md`
//   padding-right:35px;
//   padding-top:10px;
// padding-bottom:10px;
//  `}

// svg{
//   transition: all 0.1s linear;
//   position:absolute;
//   right:0;
//   ${BreakpointDown.md`
//     right:-15px;
//  `}
// }
// &:before{
//     content:'';
//     background:#F8F8F8;
//     position:absolute;
//     left:0;
//     right:-40px;
//     top:0;
//     bottom:0;
//     display:none;
//     ${BreakpointDown.xl`
//      right:0;
//     `}
//   }
// &+&{
//   border-top: 1px solid #00000033;
// }
// &.active{
//   >div{
//     color:#000;
//   }
//   svg{
//     transform: rotate(45deg);
//     path{
//       fill:#000;
//     }
//   }
//   .icon{
//      display:block;
//      ${BreakpointDown.xl`
//     display:none;
//     `}
  
//   }
// &:before{
//   display:block;
// }
// }
// `
// const Text = styled.div`
//   position:relative;
//   z-index:1;
//   display:flex;
//   justify-content:space-between;
//   align-items:center;
//   width: 100%;
//   color:#666666;
// `
// const Icon = styled.div`
//   position:absolute;
//   right:-30px;
//   display:none;
//   margin-top: 8px;
//   transform: rotate(224deg);

//  svg{
//    margin-left:15px;
//    path{
//      fill:#000;
//    }
//  }
// `
// const TabContnet = styled.div`
//   position:relative;
//   z-index:1;
 
// `
// const TabContentItem = styled.div`
//   animation-duration: 1s;
//   animation-name: ${FadeIn};
//   animation-fill-mode: both;
//   transition: opacity 0.15s linear;
//   &.tab-item{
//     ${BreakpointUp.xl`
//     display:none;
//     `
//   }
//     &.active{
//       ${BreakpointUp.xl`
//         display:inherit;
//         `
//   }
//     .tab-content-inner{
//       padding-top:20px;
//       height:auto;
//       overflow:auto;
//     }
//     }
//   }
// `
// const TabContentinner = styled.div`
//    ${BreakpointDown.xl`
//       height:0;
//       overflow:hidden;
//       transition: height 0.15s linear; 
//     `
//   }
// `

function FaqPage() {
  // const [activeTab, setactiveTab] = useState(0);
  // const HandleTabs = (key) => {
  //   setactiveTab(key);
  // };
  return (
    <Layout pageName="faq">
      <SEO title="Faq" />
      <SectionBgAfter pt="156px" pb="100px" xpt="90px" xpb="60px" mpb="40px" bgAfter={`url(${BgImage})`}>
        <div className="container">
          <BreadCrumbWhite>
            <Link to="/">Home</Link><Link to="/#">Faq</Link>
          </BreadCrumbWhite>
          <PageTitleWrapper>
            <PageTitle>frequently asked questions?</PageTitle>
            {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
          </PageTitleWrapper>
        </div>
      </SectionBgAfter>
      <SectionBg bgBefore="#fff" bgAfter="#F8F8F8">
        <div className="container">
          <ComingSoon textAlign="center">Coming Soon...</ComingSoon>
          {/* <FaqRow>
            <FaqColumn>
              <TabListDesktop>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => (<TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>Lorem Ipsum is simply dummy text of the printing type setting? <PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                </TabListItem>
                ))}
              </TabListDesktop>
            </FaqColumn>
            <FaqColumn>
              <TabContnet>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                  return (
                    <TabContentItem key={index} className={activeTab === index ? 'tab-item active' : 'tab-item'} >
                      <TabListMobile> <TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>Lorem Ipsum is simply dummy text of the printing type setting?<PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                      </TabListItem></TabListMobile>
                      <TabContentinner className="tab-content-inner">
                        <p>Lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                        <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged lorem Ipsum is simply dummy text of the printing and type setting industry lorem Ipsum has been industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</p>
                      </TabContentinner>
                    </TabContentItem>
                  )
                })}
              </TabContnet>
            </FaqColumn>
          </FaqRow> */}
        </div>
      </SectionBg>
    </Layout>
  )
}
export default FaqPage